export type Features = { [key in keyof typeof defaultFeatures]?: boolean };

export const defaultFeatures = {
    "eventSimDif": false,
    "experimentalCharts": false,
    "universalInbox": false,
    "sequenceTriggerAlertInbox": false,
    "readonlyToggle": false,
    "displayTokenSecrets": false,
    "universalTicketInbox": false,
    "incidentSimilarityRatings": false,
    "resizableTables": false,
    "testInbox": true,
    "salesforceSandboxIntegration": false,
    "riskSchedulesDevMode": false,
    "newWatchlistPage": true,
    "communityWatchlists": true,
    "addToWatchlistFromSearchTable": true,
    "ticketExternalId": false,
    "triggerAnalysis": false,
    "defaultTriggerGroupAnalysis": false,
    "luceneSyntaxHighlighting": true,
    "escalationPage": true,
    "bulkTriggerActions": false,
    "newEditor": false,
    "infoPanel": true,
    "prevNextNavigation": true,
    "classifierLibrary": true,
    "cyberRiskReadinessBanner": true,
    "cyberRiskReadinessDashboard": true,
    "copyRiskObjects": true,
    "inventoryNestedLinks": true,
    "frameworksActiveStatus": true,
    "defaultRevealDashboards": true,
    "automatedIntervention": false,
    "jobDescriptors": true,
    "adblockDetector": false,
    "uebaAlertDetailsView": false,
    "riskContextualScores": true,
    "bulkTicketCsv": true,
    "bulkEditPrimaryCopies": false,
    "criticalTriggers": true,
    "newPhoneNumberFormat": false,
    "crrMapping": false,
    "maturityTiers": false,
    "newDashboardsWorkflow": false,
    "upgradedAssessmentPage": false,
    "contextualScoresDataSource": false,
    "mappingFramework": false,
    "iconUpdate": false,
};

export const orgFeatureOverrides: Record<uuid, Features> = {
    // main dev account
    "08b1764cc508425699cbf853976d7f40": {
        "eventSimDif": true,
        "experimentalCharts": true,
        "universalInbox": true,
        "sequenceTriggerAlertInbox": true,
        "readonlyToggle": true,
        "universalTicketInbox": true,
        "salesforceSandboxIntegration": true,
        "riskSchedulesDevMode": true,
        "ticketExternalId": true,
        "addToWatchlistFromSearchTable": true,
        "triggerAnalysis": true,
        "defaultTriggerGroupAnalysis": false,
        "bulkTriggerActions": false,
        "newEditor": true,
        "prevNextNavigation": true,
        "classifierLibrary": true,
        "defaultRevealDashboards": true,
        "cyberRiskReadinessBanner": true,
        "infoPanel": true,
        "crrMapping": true,
        "newDashboardsWorkflow": true,
        "mappingFramework": true
    },
    // DefensesStorm Audit (in dev)
    "0aed56bbf8954c86a010925a939a577e": {},
    // Dev Integration Test Account
    "ff33e3055c584df49ebcb04a4b4f7686": {},
    // Dev CRR Test Org
    "12f7e4a30128497e997b8f950100448a": {
        "cyberRiskReadinessBanner": true,
        "frameworksActiveStatus": true,
    },
    // Defensestorm Engineering
    "6772e7be9f61473d9f253f5ce63fe0f3": {
        "universalInbox": true,
        "readonlyToggle": true,
        "universalTicketInbox": true,
    },
    // DefenseStorm TRAC
    "3da3b9e330704ef7966745ef6b5fef32": {
        "universalTicketInbox": true,
    },
    // DefenseStorm Customer Success
    "2d5afc09aa3743f5ac104e236c7421e6": {
        "universalTicketInbox": true,
    },
    // Example FI
    "5313b8df99274acb8da7129a774d4251": {},
};
