import { booleanAttribute, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";

export type DsButtonType = "standard" | "delete" | "cancel" | "outlined" | "text" | "blue-link" |
    "black-link" | "red-link" | "warn" | "standard-green";

@Component({
    selector: "ds-button",
    template: `
        <button [ngClass]="classes" (click)="onClick($event)" [disabled]="disabled">
            <ng-content></ng-content>
        </button>
    `,
})
export class DsButtonComponent implements OnInit, OnChanges {
    @Input() size: ("small" | "medium" | "large") = "medium";
    @Input() type: DsButtonType = "standard";
    @Input({ transform: booleanAttribute }) icon: boolean = false;
    @Input() disabled: boolean = false;
    @Output() clicked = new EventEmitter();

    classes: string[];

    constructor() {
    }

    ngOnInit() {
        this.updateClasses();
    }

    ngOnChanges() {
        this.updateClasses();
    }

    onClick(event) {
        this.clicked.emit(event);
    }

    private updateClasses() {
        this.classes = [this.size, this.type];
        if (this.icon) {
            this.classes = [...this.classes, "icon"];
        }
    }
}
